// import { store } from "../store/store";
import ApiService from "./ApiService";
import { store } from "../store/store";
import {
  setPeriodDetails,
  setPeriodCyclesWithPrediction,
} from "../store/appStore/period";
class PeriodService {
  async getPeriodDetail() {
    return ApiService.get("/period-phases/get-current-phase").then(
      (periodDetails) => {
        store.dispatch(setPeriodDetails(periodDetails));
        return periodDetails;
      }
    );
  }

  async getAllPeriodDetailsWithPrediction() {
    return ApiService.get("/period-phases/with-perdiction").then(
      (periodDetails) => {
        store.dispatch(setPeriodCyclesWithPrediction(periodDetails));
        return periodDetails;
      }
    );
  }

  async updatePeriodCycle({ periodStartDate, periodLength }, currentCycleId) {
    return ApiService.put(`/period-cycles/${currentCycleId}`, {
      lastPeriodStartDate: periodStartDate,
      periodLength: periodLength,
      isUpdated: true,
    }).then(() => {
      return this.getPeriodDetail();
    });
  }

  async deletePeriodCycle(currentCycleId) {
    return ApiService.delete(`/period-cycles/${currentCycleId}`);
  }

  async addPeriodCycle({ periodStartDate, periodLength }, auth, periodDetails) {
    return ApiService.post(`/period-cycles`, {
      lastPeriodStartDate: periodStartDate,
      periodLength: periodLength,
      averageDurationOfCycle:
        periodDetails.currentPeriodCycleModel.averageDurationOfCycle,
      user: auth,
      isUpdated: true,
    }).then(() => {
      return this.getPeriodDetail();
    });
  }

  async getDailySymptoms(user) {
    return ApiService.get(
      `/daily-symptoms?user=${user.id}&_limit=1&_sort=id:desc`
    );
  }
}

export default new PeriodService();
