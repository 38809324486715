import React from 'react'
import { ToastContainer } from "react-toastify";

export default function AppToastNotification() {
    return (
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            pauseOnFocusLoss
            draggable = {false}
            pauseOnHover
        />
    )
}
