import { get } from "lodash";
import { customSortedPhaseType } from "../utils";
export const isValidAuthSelector = (state) => state.route.meta.isAuth;
export const routeMetaSelector = (state) => state.route.meta;
export const themeColorSelector = (state) => state.themeColor.color;
export const authTokenSelector = (state) => state.auth.token;
export const unReadNotificationsCountSelector = (state) =>
  state.auth.unReadNotificationsCount;
export const fcmTokenSelector = (state) => state.auth.fcmToken;
export const loggedInUserSelector = (state) => state.auth.user;
export const dobSelector = (state) => state.auth.user.dob;
export const periodCycleSelector = (state) =>
  get(state, "auth.user.period_cycles", []);
export const getTribeTokenSelector = (state) => state.auth.tribeToken;
export const getPeriodDetails = (state) => state.period.periodDetails;
export const getCurrentPhaseModel = (state) =>
  state.period.periodDetails.phaseModel;
export const getCurrentDietModel = (state) =>
  state.period.periodDetails.dietModel;
export const getPeriodCyclesWithPrediction = (state) =>
  state.period.periodCyclesWithPrediction;
export const getCurrentPeriodCycleSelector = (state) =>
  state.period.periodDetails.currentPeriodCycleModel;
export const periodDayDetails = (state) =>
  state.period.periodDetails.phasesInformation;
export const getExcercises = (state) =>
  get(state, "period.periodDetails.phaseModel.exercises", []);

export const faqCategoriesSelector = (state) =>
  get(state, "faq.faqCategories", []);
export const fastingBannersInfo = (state) =>
  get(state, "fasting.fastingBanners", []);

export const getCurrentPhaseCode = (state) =>
  get(state, "period.periodDetails.currentPhaseInfo.currentPhase", "");

export const flowListSelector = (state) => state.cycle.flows;
export const symptomsListSelector = (state) => state.cycle.symptoms;

export const fastStartTimeSelector = (state) => state.fastConfig.startTime;
export const fastEndTimeSelector = (state) => state.fastConfig.endTime;
export const isActiveFast = (state) => state.fastConfig.isActiveFast;
export const getFastDuration = (state) =>
  get(state, "fastConfig.fastDuration", 0);

export const fastCompletedDurationSelector = (state) =>
  state.fastConfig.fastCompletedDuration;
export const scheduledFastSelector = (state) =>
  state.fastConfig.scheduledFast;
export const isCustomChangedDate = (state) => state.fastConfig.isCustomChangedDate;
export const getFastingListSelector = (state) => state.fasting.fastingList;
export const getFaqListSelector = (state) =>
  get(state, "period.periodDetails.phaseModel.faqs", []);
export const appVersionSelector = (state) => state.version;
export const activeFastIdSelector = (state) =>
  state.fastConfig.activeFastConfig.id;
export const activeFastSelector = (state) => state.fastConfig.activeFastConfig;
export const defaultFastTimeSelector = (state) => state.fastConfig.defaultTime;
export const fastHistorySelector = (state) =>
  state.fastConfig.fastHistoryConfig;

export const homePageBannerSelector = (state) => state.home.banners;
export const homeCycleAlignDescription = (state) =>
  state.home.cycleAlignDescription;
export const foodsToEatNowSelector = (state) =>
  get(state, "period.periodDetails.phaseModel.foodsToEatNow", []);
export const foodsToEatNowMapSelector = (state) =>
  new Map(
    get(
      state,
      "period.periodDetails.phaseModel.foodsToEatNow",
      []
    ).map((food) => [food.id, food])
  );
export const foodTipsSelector = (state) =>
  get(state, "period.periodDetails.phaseModel.foodTips", []);
export const recommondedDailyMustEatsSelector = (state) =>
  get(state, "period.periodDetails.phaseModel.recommondedDailyMustEats", []);
export const activitiesSelector = (state) =>
  get(state, "period.periodDetails.phaseModel.Activities", []);
export const periodPhasesSelectors = (state) =>
  customSortedPhaseType(get(state, "period.periodDetails.periodPhases", []));
export const periodWhatsHappeningSelectors = (state) =>
  get(state, "period.periodDetails.phaseModel.whatsHappingIcons", []);
export const recommendedDietSelector = (state) =>
  get(state, "period.periodDetails.dietModel", {});
export const weightLossDietSelector = (state) =>
  get(state, "weightLossDiet.diet", []);
export const blogCategoriesSelector = (state) =>
  get(state, "blog.categories", []);
export const reminderPreferenceSelector = (state) =>
  get(state, "reminderPreference", {});
export const tribleTopicsSelector = (state) => get(state, "tribe.topics", []);
export const showTourSelector = (state) => state.showTour.isShowTour;
