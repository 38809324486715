
import firebase from "firebase/app";
import "firebase/messaging";
import {firebaseConfig} from "../config";
import { setFCMToken } from "../store/appStore/auth";
import { dispatch } from "../store/store";
import AuthService from "./AuthService";

class FirebaseService {
    initFirebase() {
        firebase.initializeApp(firebaseConfig);
        const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
        if (messaging) {
            messaging.getToken({ vapidKey: 'BFe5GzsWhtZsg1n35hrq1JaeN8nxis8hkXLDkNAtc7RKt-P0oDhBnRorvBTnaelbw2Yt5ja4EBjDdVoiSJcUICA' }).then((currentToken) => {
                if (currentToken) {
                    dispatch(setFCMToken(currentToken));
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });

            messaging.onMessage((payload) => {
                // TODO: Remove comment
                console.log('Message received. ', payload);
                setTimeout(() => {
                    AuthService.fetchNoticationCount();
                }, 1000);
            });

            navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
        }


    }
}

export default new FirebaseService();
