import createStore from "../createStore";

const initialState = {
  meta: { isAuth: false },
};

const [{ setRouteState }, reducer] = createStore("Inaari/routeState", {
  _initialState: initialState,

  setRouteState: (route) => (state) => ({
    ...state,
    ...route,
  }),
});

export { setRouteState };
export default reducer;
