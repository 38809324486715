import createStore from "../createStore";

const initialState = {};

const [{ setReminderPreference }, reducer] = createStore(
  "Inaari/reminderPreference",
  {
    _initialState: initialState,

    setReminderPreference: (preference) => (state) => ({
      ...state,
      ...preference,
    }),
  }
);

export { setReminderPreference };
export default reducer;
