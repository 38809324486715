import { combineReducers } from "redux";
import initApp from "./appStore/appInit";
import route from "./appStore/routeState";
import themeColor from "./appStore/globalThemeColor";
import auth from "./appStore/auth";
import period from "./appStore/period";
import fasting from "./appStore/fasting";
import faq from "./appStore/faq";
import home from "./appStore/home";
import cycle from "./appStore/flowSymptomList";
import fastConfig from "./appStore/fastConfig";
import version from "./appStore/version";
import blog from './appStore/blog';
import reminderPreference from './appStore/reminderPreference';
import tribe from './appStore/tribe';
import weightLossDiet from './appStore/weightLossDiet';
import showTour from './appStore/showTour';

export default combineReducers({
  initApp,
  showTour,
  route,
  themeColor,
  auth,
  period,
  faq,
  fasting,
  home,
  cycle,
  fastConfig,
  weightLossDiet,
  version,
  blog,
  reminderPreference,
  tribe
});
