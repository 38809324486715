import Axios from "axios";
import config from "../config";
import { get } from "lodash";

const axiosInstance = Axios.create({
  baseURL: config.apiBaseUrl,
});

class ApiService {
  get axios() {
    // Alter defaults after instance has been created
    if (localStorage.getItem("token")) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
    }
    return axiosInstance;
  }
  get(url, configs = {}) {
    return this.axios
      .get(url, configs)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  }
  post(url, data, configs = {}) {
    return this.axios
      .post(url, data, configs)
      .then((response) => response.data)
      .catch((error) => {
        const data = get(error, "response.data");
        if (data) {
          throw data;
        } else {
          throw error;
        }
      });
  }
  put(url, data, configs = {}) {
    return this.axios
      .put(url, data, configs)
      .then((response) => response.data)
      .catch((error) => {
        const data = get(error, "response.data");
        if (data) {
          throw data;
        } else {
          throw error;
        }
      });
  }
  delete(url, configs = {}) {
    return this.axios
      .delete(url, configs)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response.data;
      });
  }
}

export default new ApiService();
