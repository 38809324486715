import React from 'react'
import profileImg from "../../../assets/icons/profile-user.svg";
import faqImg from "../../../assets/icons/publications.png";

import accountSettingImg from "../../../assets/icons/settings.svg";
import reminderImg from "../../../assets/icons/reminders.svg";
import termsOfUseImg from "../../../assets/icons/accept.svg";
import privacyImg from "../../../assets/icons/clipboard.svg";
import supportImg from "../../../assets/icons/mail.svg";
import { LinkNav } from "../../core/LinkNav";
import AppImage from '../../core/AppImage';

export default function SubMenuComponent({ styles, onSubMenuClick }) {
  return (
    <div className={`dropdown-list-menu ${styles.displayBlock}`}>
      <LinkNav url="health-profile" className="dropdown-item" onClick={onSubMenuClick}>
        <span className="iconImg">
          <AppImage src={profileImg} alt="Profile" />
        </span>{" "}
          Health Profile
  </LinkNav>
    <LinkNav
    url="faq"
    className="dropdown-item" 
    onClick={onSubMenuClick}>
      <span className="iconImg">
        <AppImage src={faqImg} alt="FAQs" />
      </span>{" "}
      FAQs
    </LinkNav>
      <LinkNav
        url="account-settings"
        className="dropdown-item"
        onClick={onSubMenuClick}
      >
        <span className="iconImg">
          <AppImage src={accountSettingImg} alt="Account Settings" />
        </span>{" "}
    Account Settings
  </LinkNav>
      <LinkNav
        url="reminders"
        className="dropdown-item"
        onClick={onSubMenuClick}
      >
        <span className="iconImg">
          <AppImage src={reminderImg} alt="Reminders" />
        </span>{" "}
    Reminders
  </LinkNav>
      <LinkNav
        url="terms-of-use"
        className="dropdown-item"
        onClick={onSubMenuClick}
      >
        <span className="iconImg">
          <AppImage src={termsOfUseImg} alt="Terms of Use" />
        </span>{" "}
    Terms of Use
  </LinkNav>
      <LinkNav
        url="privacy-policy"
        className="dropdown-item"
        onClick={onSubMenuClick}
      >
        <span className="iconImg">
          <AppImage src={privacyImg} alt="Privacy Policy" />
        </span>{" "}
    Privacy Policy
  </LinkNav>
      {/* <LinkNav
                url="publications"
                className="dropdown-item"
                onClick={onSubMenuClick}
            >
                <span className="iconImg">
                    <AppImage src={publicationImg} alt="Publications" />
                </span>{" "}
    Publications
  </LinkNav> */}
      <LinkNav url="support" className="dropdown-item" onClick={onSubMenuClick}>
        <span className="iconImg">
          <AppImage src={supportImg} alt="Support" />
        </span>{" "}
    Support
  </LinkNav>
    </div>
    )
}
