import React from "react";
import { NavLink } from "react-router-dom";

export function LinkNav({ text = "", children, url, params = {}, ...prop }) {
  return (
    <NavLink  {...prop} to={`/${url}`}>
      {!!text.length ? text : children}
    </NavLink>
  );
}
