import createStore from "../createStore";

const initialState = {
  isShowTour: false,
};

const [{ setShowTour }, reducer] = createStore("Inaari/tour", {
  _initialState: initialState,

  setShowTour: (isShowTour) => (state) => ({
    ...state,
    isShowTour,
  }),
});

export { setShowTour };
export default reducer;
