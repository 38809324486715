import React from 'react';
import { appMainMenu } from '../../../constants'
import AppImage from '../../core/AppImage';
import { LinkNav } from '../../core/LinkNav'

export default function MainMenuComponent({onMenuClose}) {
    return (
        <div id="mySidenav" className="sidenav" onClick={onMenuClose}>
            <div className="closebtn pointer">&times;</div>
            <nav className="sidebar bg-light">
                <div className="sidebar-sticky sidebar-leftNav">
                    <ul className="nav flex-column">
                        {
                            appMainMenu && appMainMenu.map(menu => {
                                return (
                                    <li className="nav-item" key={menu.name}>
                                        <LinkNav className="nav-link d-flex justify-content-start align-items-center" url={menu.url}>
                                            <span className="d-flex align-items-center feather">
                                                {!!menu.menuIcon ? <i className={`fas ${menu.menuIcon}`}></i> :  <AppImage src={menu.image} alt=""/> }
                                            </span>
                                            {menu.name}
                                        </LinkNav>
                                    </li>
                                )
                            })
                        }
                       
                        <li className="nav-item mt-5">
                            <LinkNav className="btn btn-warning btn-block btn-rounded-pill text-uppercase shadow-blue" url="logout">logout</LinkNav>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}
