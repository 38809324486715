import { setVerisonInfo } from "../store/appStore/version";
import { dispatch } from "../store/store";
import ApiService from "./ApiService";

class StaticPageService {
  async fetchTermsAndConditions() {
   return ApiService.get(`/terms-and-conditions`).then((response) => {
        return response.termsAndCondition
    });
  }

  async fetchPrivacyPolicy() {
    return ApiService.get(`/privacy-and-policy`).then((response) => {
         return response.policy
     });
   }

   async fetchPublication() {
    return ApiService.get(`/publication`).then((response) => {
         return response.publication
     });
   }

   async getAppVersion() {
     return ApiService.get('/support').then((response) => {
        dispatch(setVerisonInfo(response))
     });
   }


}

export default new StaticPageService();
