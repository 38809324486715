import createStore from "../createStore";

const initialState = [];

const [{ setWeightLossDiet }, reducer] = createStore("Inaari/weightLossDiet", {
  _initialState: initialState,

  setWeightLossDiet: (weightLossDiets) => (state) => ({
    ...state,
    diet: weightLossDiets,
  }),
});

export { setWeightLossDiet };
export default reducer;
