import { get } from "lodash";
import { toast } from "react-toastify";
import swal from "sweetalert";
import qs from "qs";
import {
  setAuth,
  setTribeToken,
  setUnReadNotificationsCount,
} from "../store/appStore/auth";
import { dispatch, getState, store } from "../store/store";
import { fcmTokenSelector, getCurrentPhaseModel } from "../store/selectors";
import apiService from "./ApiService";
import StaticPagesService from "./StaticPagesService";
import FastingService from "./FastingService";
import ReminderPreferenceService from "./ReminderPreferenceService";
import { setBlogCategories } from "../store/appStore/blog";
import HomeService from "./HomeService";
import { setFaqCategories } from "../store/appStore/faq";
import config from "../config";
import { setShowTour } from "../store/appStore/showTour";
import CookieService from "./CookieService";
class AuthService {
  constructor() {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    store.dispatch(setAuth({ token, user }));

    if (token) {
      StaticPagesService.getAppVersion();
      FastingService.getActiveFastConfig();
      ReminderPreferenceService.getReminderPreference();
      this.fetchTribeAuthToken();
      this.getBlogCategories();
      HomeService.onHomePageReady();
    }
  }

  getPhase() {
    return getCurrentPhaseModel(getState())
      ? getCurrentPhaseModel(getState()).title
      : "Phase";
  }

  async loginWithEmailAndPassword({ email, password }) {
    try {
      const response = await apiService.post("/auth/local", {
        identifier: email,
        password,
      });

      return this.afterLogin(response);
    } catch (error) {
      const errorMessage = get(error, "message.0.messages.0.message");
      if (errorMessage === "Please provide your username or your e-mail.") {
        toast.error("Email not registered!");
      }

      if (errorMessage === "Identifier or password invalid.") {
        toast.error("Email or password invalid!");
      }

      if (errorMessage === "Your account email is not confirmed") {
        toast.info(
          "This email is already registered, please verify your email via the confirmation email that we have sent you.  Need help? Contact us at hello@inaari.org"
        );
      }
    }
  }

  async register(formValues) {
    try {
      const response = await apiService.post("/auth/local/register", {
        ...formValues,
        username: formValues.email,
      });

      return this.afterLogin(response);
    } catch (error) {
      console.log(error);
      const formValidationError = get(error, "data.0.messages.0.message");
      if (formValidationError) {
        toast.error(formValidationError);
      }

      throw error;
    }
  }

  async deleteUser(userId) {
    try {
      await apiService.delete(`/users/${userId}`);
    } catch (e) {
      toast.error(e);
    }
  }

  async afterLogin(response) {
    if (response.jwt) {
      localStorage.setItem("token", response.jwt);
    } else {
      swal({
        title: "Success",
        text:
          "Thank you for registering. We have sent you a verification email. Once you confirm your email, you will be able to log in",
        icon: "success",
      });
      return;
    }
    localStorage.setItem("user", JSON.stringify(response.user));
    const isTribeLogin = localStorage.getItem("isTribeLogin");
    const redirect =
      CookieService.getCookie("redirect") || localStorage.getItem("redirect");

    if (isTribeLogin && isTribeLogin === "true") {
      localStorage.removeItem("isTribeLogin");
      const tribeToken = await this.fetchTribeAuthToken();
      const redirectUrl = `${config.sisterhoodUrl}/auth/sso?ssoToken=${tribeToken}`;
      window.location.replace(redirectUrl);
    }

    if (redirect) {
      this.redirectBack(redirect, response.jwt);
    }

    store.dispatch(setAuth({ token: response.jwt, user: response.user }));
    await Promise.all([
      this.fetchTribeAuthToken(),
      this.setFCMTokenToServer(),
      this.getBlogCategories(),
      this.setFAQCategory(),
    ]);
    HomeService.onHomePageReady();
    return response;
  }

  redirectBack(redirect, token) {
    const url = new URL(redirect);

    const host = url.origin;
    const pathname = url.pathname;
    const search = qs.parse(url.search, { ignoreQueryPrefix: true });
    const queryString = qs.stringify({ ...search, token });

    const redirectUrl = `${host}${pathname}?${queryString}`;
    localStorage.removeItem("redirect");
    CookieService.deleteCookie("redirect");
    localStorage.removeItem("onErrorRedirectTo");
    CookieService.deleteCookie("onErrorRedirectTo");
    window.location.replace(redirectUrl);
  }

  async setFCMTokenToServer() {
    const fcmDeviceToken = fcmTokenSelector(getState());
    const loggedInUser = this.getLoggedInUser();
    await apiService.put(`/users/${loggedInUser.id}`, {
      fcmDeviceToken,
    });
  }

  async fetchTribeAuthToken() {
    const url = "/tribe/get-jwt-token";
    const token = await apiService.get(url);
    store.dispatch(setTribeToken(token));
    return token;
  }

  async socialLogin({ provider, accessToken }) {
    const response = await apiService.get(
      `/auth/${provider}/callback?${qs.stringify({
        access_token: accessToken,
      })}`
    );

    return this.afterLogin(response);
  }

  async forgotPassword(data) {
    await apiService.post("/auth/forgot-password", data);
    toast.success("Reset password instruction sent on your email.");
  }

  async resetPassword(data) {
    const response = await apiService.post("/auth/reset-password", data);
    return this.afterLogin(response);
  }

  getLoggedInUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  getLoggedInUserToken() {
    return localStorage.getItem("token");
  }

  async createFirstCycleInfo(data) {
    const loggedInUser = this.getLoggedInUser();
    const responses = await Promise.all([
      apiService.put(`/users/${loggedInUser.id}`, {
        periodType: data.periodType,
      }),
      apiService.post("/period-cycles/create-first-period", {
        lastPeriodStartDate: data.lastPeriodStartDate,
        periodLength: data.periodLength,
        averageDurationOfCycle: data.averageDurationOfCycle,
        user: loggedInUser.id,
      }),
    ]);
    console.log(responses);
    await this.afterLogin({
      jwt: this.getLoggedInUserToken(),
      user: responses[0],
    });
    store.dispatch(setShowTour(true)); // showing tour user who comes first time on the platform
    return responses;
  }
  async getBlogCategories() {
    const blogCategories = await apiService.get("/tags");
    if (blogCategories) {
      const categories = blogCategories.map((item) => item.tag);
      dispatch(setBlogCategories(categories));
    }
  }

  async setFAQCategory() {
    const faqCategories = await apiService.get("/app-faq-categories");
    if (faqCategories.length) {
      dispatch(setFaqCategories(faqCategories));
    }
  }

  async fetchNoticationCount() {
    const loggedInUser = this.getLoggedInUser();
    if (loggedInUser && loggedInUser.id) {
      const count = await apiService.get("/user-notifications/count", {
        params: {
          "user.id": loggedInUser.id,
          hasRead: false,
        },
      });

      store.dispatch(setUnReadNotificationsCount(count));
    }
  }
  async fetchNoticationCountForState(config = {}) {
    const count = await apiService.get("/user-notifications/count", config);
    store.dispatch(setUnReadNotificationsCount(count));
    return count;
  }
}

export default new AuthService();
