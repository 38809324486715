import createStore from "../createStore";

const initialState = {
  color: "",
};

const [{ setThemeColor }, reducer] = createStore("Inaari/themeColor", {
  _initialState: initialState,

  setThemeColor: (color) => (state) => ({
    ...state,
    color,
  }),
});

export { setThemeColor };
export default reducer;
