import React from "react";
import {GuardedRoute } from 'react-router-guards';

import FooterComponent from "../app/FooterComponent/FooterComponent";
import HeaderComponent from "../app/HeaderComponent/HeaderComponent";

const RouteLayer = ({meta, path, exact, component}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={!!meta.globalClassName ? meta.globalClassName : ''}>
      {(meta.isAuth && meta.showHeader) && <HeaderComponent routeMeta={meta}/>}
        <div className="appInnerWrapper">
          <GuardedRoute exact={exact} meta={meta} path={path}  component={component}/>
        </div>
      {(meta.isAuth && meta.showHeader) && <FooterComponent />}
    </div>
  );
};

export default RouteLayer;
