import React, {useEffect} from 'react'
import useApi from '../../../hooks/useApi'
import { formatDistanceToNow } from 'date-fns'
import ApiService from '../../../services/ApiService';

export default function HeaderNotificationComponent({ onMenuClose, user, setUnReadNotificationsCount }) {

    const [notifications] = useApi(['user-notifications'], { params: {
        'user.id': user.id,
        '_sort': 'created_at:desc',
        '_limit': 20
    }});

    useEffect(() => {
        setTimeout(() => {
            ApiService
                .put('/user-notifications/mark-all-read', {
                    params: { 'user.id': user.id, hasRead: false }
                })
                .then(() => setUnReadNotificationsCount(0));
        }, 2000);
    }, [setUnReadNotificationsCount, user.id]);

    return (
        <div className="sidenav align-items-start">
            <div className="notification-ui_dd">
                <div className="notification-ui_dd-header">
                    <h3 className="text-center">Notification</h3>
                    <div className="closebtn pointer" onClick={onMenuClose}>&times;</div>
                </div>
                <div className="notification-ui_dd-content">
                    {
                        notifications && notifications.length && notifications.map(notification => (
                            <div key={notification.id} className={`notification-list ${notification.hasRead ? 'notification-list--read' : 'notification-list--unread'}`}>
                                {/* <div className="notification-list_img">
                                    <img src="https://i.imgur.com/zYxDCQT.jpg" alt="user" />
                                </div> */}
                                <div className="notification-list_detail">
                                    <p><b>{notification.title}</b></p>
                                    <p>{notification.body}</p>
                                    <p><small>{formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })}</small></p>
                                </div>
                                {/* <div className="notification-list_feature-img">
                                    <img src="https://i.imgur.com/AbZqFnR.jpg" alt="" />
                                </div> */}
                            </div>
                        ))
                    }

                </div>
                {/* <div className="notification-ui_dd-footer">
                    <a href="#!" className="btn btn-success btn-block">View All</a>
                </div> */}
            </div>
        </div>
    )
}
