import React from "react";
import { LinkNav } from "../../core/LinkNav";
import style from "./FooterComponent.module.scss";

export default function FooterComponent() {
  return (
    <nav className="bg-white footer-top-lef-right footer-shadow footer-navbar fixed-bottom footer-fixed-bottom">
      <div className="container-fluid p-0">
        <div className="row m-0 w-100">
          <div className="col-md-12 col-xs-12 p-0">
            <div className="d-flex footerNav justify-content-around navbar-nav">
              <div className="nav-item text-center" id="forYou">
                <LinkNav activeClassName="activeFooterRoute" url="home" className="nav-link">
                  <div className={`${style.spriteIcons} ${style.forYou} foryou`}></div>
                  For You
                </LinkNav>
              </div>
              <div className="nav-item text-center" id="weightLoss">
                <LinkNav url="weight-loss" activeClassName="activeFooterRoute" className="nav-link">
                  <div
                    className={`${style.spriteIcons} ${style.weightLoss} weightloss`}
                  ></div>
                  Weight Loss
                </LinkNav>
              </div>
              <div className="nav-item text-center" id="community">
                <LinkNav url="community" activeClassName="activeFooterRoute" className="nav-link">
                  <div
                    className={`${style.spriteIcons} ${style.community} communitiy`}
                  ></div>
                  Sisterhood
                </LinkNav>
              </div>
              <div className="nav-item text-center" id="fasting">
                <LinkNav url="start-fasting" activeClassName="activeFooterRoute" className="nav-link">
                  <div
                    className={`${style.spriteIcons} ${style.fasting} fasting`}
                  ></div>
                  Fasting
                </LinkNav>
              </div>
              <div className="nav-item text-center" id="blog">
                <LinkNav url="blog" activeClassName="activeFooterRoute" className="nav-link">
                  <div className={`${style.spriteIcons} ${style.blog} blog`}></div>
                  Blog
                </LinkNav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
