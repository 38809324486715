import React from 'react'
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import profileCoverPhoto from "../../../assets/girl-image-placeholder.jpg";
import { loggedInUserSelector } from '../../../store/selectors';
import { LinkNav } from '../../core/LinkNav';
import { getAssetUrl } from '../../../utils';
import AppImage from '../../core/AppImage';

function ProfileWidgetComponent({user, styles}) {
    return (
        <div className={`container-fluid ${styles.profileCol}`}>
        <div className="row justify-content-center align-items-center">
          <div className="col-md-12 col-xs-12 p-0">
            <div className="d-flex flex-row">
              <div className={`p-0 ${styles.profileCoverPhotoImg}`}>
                <AppImage src={!!user.profilePicture ? getAssetUrl(user.profilePicture) : profileCoverPhoto} alt={user.username} className="img-raised rounded-circle img-fluid" />
              </div>
              <div className={`${styles.profileContentCol}`}>
                <h3 className="font-size-base font-weight-lg">{`${isNil(user.name) ? user.username : user.name}`}</h3>
                <p className="font-size-msmall mb-1"><span className="text-danger mr-2"><i
                    className="fal fa-paper-plane"></i></span>{user.username}</p>
                {
                  (!!user && !!user.phone) && (
                    <p className="font-size-msmall mb-1"><span
                    className="text-danger mr-2"><i className="fal fa-phone-volume"></i></span> {user.phone} </p>
                  )
                }  

                 {
                  (!!user && !!user.dob) && (
                    <p className="font-size-xsmall"><span className="text-danger mr-2"><i
                  className="fal fa-birthday-cake"></i></span> {moment(user.dob).format('DD MMM YYYY')}</p>

                  )
                }    
              
                
              </div>
            </div>
          </div>
          <p className="my-4 text-center ml-4">
            {/* <LinkNav url="upgrade-premium" className="btn btn-warning btn-block btn-rounded-pill text-uppercase btn-shadow-light-gold btn-rounded-pill-space text-center">
              Upgrade to premium
            </LinkNav> */}
            <LinkNav url="logout" className="btn btn-warning btn-block btn-rounded-pill text-uppercase btn-shadow-light-gold btn-rounded-pill-space text-center">
              Logout
            </LinkNav>
    
          </p>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state),
});

export default connect(mapStateToProps)(ProfileWidgetComponent);