import React from "react";
import { LinkNav } from "../../core/LinkNav";
import errorImg from '../../../assets/404.png'
import style from "./ErrorComponent.module.scss";
import AppImage from "../../core/AppImage";

export default function ErrorComponent({errorHTML = null}) {
  return (
    <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-xs-12 flex-column  justify-content-center  d-flex align-items-center">
            <div className="img-fluid">

            <AppImage src={errorImg} style={{width: '100%'}}/>
            </div>
            <div className="heading-sm font-weight-lg">Something went wrong</div>
            <p className="text-center mt-3">
           {
           !!errorHTML ? errorHTML
           : <>We're sorry, the page you requested could not be found. <br/>
            Please contact us at support@inaari.org.</>}
            </p>
            <LinkNav 
            className={`btn btn-block btn-warning btn-rounded-pill text-uppercase btn-shadow-light-gold mt-3 ${style.anchorLink}`}
            url="home"
            onClick = {() => window.location.href = '/'}
            >Back to homepage</LinkNav>

          </div>
        </div>
     </div>
  )
}
