import React, { Component } from 'react'
import ErrorComponent from './ErrorComponent';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <ErrorComponent errorHTML = {(this.state.error && this.state.error.toString()) &&  this.state.errorInfo.componentStack}/>
        </>
      )
     
    }
    // Normally, just render children
    return this.props.children;
  }   
 
}