import createStore from "../createStore";

const initialState = {};

const [{ setVerisonInfo }, reducer] = createStore("Inaari/versionInfo", {
  _initialState: initialState,

  setVerisonInfo: (versionInfo) => (state) => ({
    ...state,
    ...versionInfo,
  }),
});

export { setVerisonInfo };
export default reducer;
