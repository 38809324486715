import React from "react";
import SubMenuComponent from "../MainMenuComponent/SubMenuComponent";
import ProfileWidgetComponent from "../ProfileComponent/ProfileWidgetComponent";


export default function HeaderSubMenuComponent({ styles, onSubMenuClick }) {
  return (
    <div id="mySidenav" className="sidenav bg-light-blue">
      <div className="closebtn pointer" onClick={onSubMenuClick}>&times;</div>
      <nav className="sidebar w-100">
        <div className="sidebar-sticky sidebar-leftNav px-3">
          <ProfileWidgetComponent styles={styles}/>
          <SubMenuComponent styles = {styles} onSubMenuClick={onSubMenuClick}/>
        </div>
      </nav>
    </div>
  )
}
