import React, { useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import CookieService from "../../services/CookieService";

export default function SetRedirectUrlPage() {
  const query = useQuery();
  const redirectAfterVerification = query.get("redirectAfterVerification");
  const redirect = query.get("redirect");

  useEffect(() => {
    if (redirectAfterVerification) {
      CookieService.setCookie(
        "redirectAfterVerification",
        redirectAfterVerification,
        1
      );
    }

    if (redirect) {
      CookieService.setCookie("redirect", redirect, 1);
    }

    // localStorage.setItem('redirectAfterVerification', redirectAfterVerification);
  }, [redirectAfterVerification, redirect]);
  return <div>Loading...</div>;
}
